<template>
  <v-card>
    <v-card-title>予約実績見積(編集)</v-card-title>
    <template>
      <v-spacer></v-spacer>
      <template v-if="apierror.status == 'error'">
        <div v-for="msg in apierror.messages" :key="msg">
          <v-row class="ml-6 mb-3 ma-3">
            <span style="color: red">* {{ msg }} </span>
          </v-row>
        </div>
      </template>
      <template>
        <v-spacer></v-spacer>
        <v-form class="ma-6">
          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label for="reserve_id">予約ID<span style="color: red">*</span></label>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <label for="reserve_id">{{ Form.resv_id }}</label>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label for="reserve_id">予約商品<span style="color: red">*</span></label>
            </v-col>
            <v-col
              cols="12"
              md="8"
            >
              <a for="reserve_id" href="javascript:void(0);" :style="'text-decoration: underline; color:#696969' " @click="dialog = true">{{ Form.po_code }} ( {{ Form.po_name_ja }} )</a>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label for="cust_id">取引先<span style="color: red">*</span></label>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <label
                    for="cust_id"
                    v-bind="attrs"
                    v-on="on"
                  >{{ Form.cust_abbr }}({{ Form.cust_id }})</label>
                </template>
                <span>{{ Form.cust_name }}</span><br>
                <span>{{ Form.cust_name_eng }}</span>
              </v-tooltip>
            </v-col>
          </v-row>

          <!-- <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label for="reserved_dt">予約日時<span style="color: red">*</span></label>
            </v-col>
            <v-col
              cols="12"
              md="8"
            >
              <label>{{ Form.resv_dt }}</label>
            </v-col>
          </v-row> -->

          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <label for="reserved_count">注文数量</label>
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <label for="resv_count">{{
                formatData(Form.resv_cnt)
              }}</label>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <label for="resv_weight">総重量</label>
            </v-col>
            <v-col
              cols="12"
              md="2"
              class="d-flex justify-end"
            >
              <label for="resv_weight">{{ formatData(Form.resv_weight) }} KG</label>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <label for="resv_amount">小計金額</label>
            </v-col>
            <v-col
              cols="12"
              md="2"
              class="d-flex justify-end"
            >
              <label for="resv_amount">{{ Form.ccy_mark }}{{ formatPrice(Form.resv_amount) }} </label>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label for="contract_status">決定金額</label>
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <PriceInputFixed
                v-model="Form.quot_f_amount"
                :fixed-num="2"
                placeholder="割引金額"
                :outlined="true"
                :prefix="Form.ccy_mark"
              ></PriceInputFixed>
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <label>{{ quot_d_amount }}</label>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <label for="contract_status">見積有効期限</label>
            </v-col>
            <v-col
              cols="12"
              md="2"
              class="py-0 my-0"
            >
              <v-menu
                v-model="dateQuotMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="Form.quot_dd"
                    placeholder="見積有効期限"
                    color="#fa7a00"
                    dense
                    hide-details="auto"
                    outlined
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="Form.quot_dd"
                  color="#fa7a00"
                  @input="dateQuotMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <label for="reserved_note">備考</label>
            </v-col>
            <v-col
              cols="12"
              md="8"
            >
              <v-textarea
                v-model="quot_note"
                name="quot_note"
                outlined
                dense
                placeholder="割引理由、変更内容等記入"
                hide-details
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              offset-md="5"
              cols="12"
            >
              <v-btn
                color="primary"
                :loading="submitStatus"
                @click="submit(Form)"
              >
                Submit
              </v-btn>
              <v-btn
                class="mx-2"
                outlined
                @click="cancelClick"
              >
                Cancel
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
        <v-dialog
          v-model="dialog"
          class="d-flex justify-center"
          max-width="500px"
        >
          <v-card class="px-8 py-6">
            <v-row>
              <v-col
                cols="12"
              >
                <span class="font">PelletID : {{ Form.pellet_id }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
              >
                <span class="font">商品名 : {{ Form.po_name_ja }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
              >
                <span class="font">商品ID : {{ Form.po_id }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="6"
              >
                <span class="font">単価 : ￥{{ formatPrice(Form.resv_amount) }}</span>
              </v-col>
              <v-col
                cols="6"
              >
                <span class="font">重量 : {{ formatData(Form.resv_weight) }} KG</span>
              </v-col>
            </v-row>
            <v-divider class="mt-5 mb-7"></v-divider>
            <v-row>
              <v-col
                cols="12"
                class="py-0"
              >
                <div v-html="Form.po_ovv_ja"></div>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                class="py-0"
              >
                <div class="d-flex align-center justify-center mb-1">
                  <v-btn
                    style="background-color: #30655f; min-height: 42px; max-width: 50px"
                    @click="dialog = false"
                  >
                    <span style="color: #fff">Ok</span>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>
      </template>
    </template>
  </v-card>
</template>

<script>
import {
  mdiPlus, mdiCalendar,
} from '@mdi/js'
import {
  mapActions, mapState, mapMutations,
} from 'vuex'

import PriceInputFixed from '@/components/PriceInputFixedComponent.vue'

export default {
  components: {
    PriceInputFixed,
  },
  data: () => ({
    icons: {
      mdiPlus,
      mdiCalendar,
    },

    submitStatus: false,
    changeFlag: false,
    apierror: [],

    dateQuotMenu: false,
    quot_note: '',
    Form: {
      id: '',
      po_id: '',
      po_name_ja: '',
      po_ovv_ja: '',
      customer_id: '',
      resv_dt: '',

      resv_count: '',
      contract_status: '',
      quot_amount: 0,
      quot_weight: 0,
    },

    resvStatuses: [],
    dialog: false,
  }),
  computed: {

    ...mapState('reservedStore', ['selectedQuotation', 'resvStatusList']),

    formatData() {
      return function (data) {
        if (typeof data === 'number') {
          return data.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }
        if (typeof data === 'string') {
          return data.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }

        return data
      }
    },

    quot_d_amount() {
      if (this.Form && this.Form.quot_o_amount && this.Form.quot_f_amount) {
        return (this.Form.quot_o_amount - this.Form.quot_f_amount).toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
      }

      return ''
    },

    formatPrice() {
      return function (data) {
        if (typeof data === 'number') {
          return data.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }
        if (typeof data === 'string') {
          return parseFloat(data).toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }

        return data
      }
    },
  },
  watch: {
    Form: {
      handler() {
        this.changeFlag = true
      },
      deep: true,
    },

  },
  mounted() {
    this.loadData()
  },
  methods: {

    ...mapActions('reservedStore', ['editQuotation', 'loadQuotation', 'loadReservationStatus']),
    ...mapMutations('app', ['setOverlayStatus']),
    cancelClick() {
      if (this.changeFlag) {
        // eslint-disable-next-line no-restricted-globals
        if (confirm('他ページに遷移します。よろしいでしょうか？')) {
          this.jumpToListPage()
        }
      }
    },
    jumpToListPage() {
      this.$router.push({
        path: '/approved-list',
        query: this.$route.params.query ?? {
        },
      })
    },

    getResvStatuses() {
      console.log('resvList:', this.resvStatusList)
      this.resvStatuses = this.resvStatusList.map(v => {
        const obj = {
        }
        obj.text = `${v.resv_stat}: ${v.resv_stat_desc}`
        obj.value = `${v.resv_stat}`

        return obj
      })
    },

    loadData() {
      this.setOverlayStatus(true)
      console.log('id:', this.$route.params.id)
      Promise.all([
        // this.loadReservationStatus(),

        this.loadQuotation(this.$route.params.id),
      ]).then(() => {
        this.getResvStatuses()
        this.Form = this.$deepCopy(this.selectedQuotation)
        if (!this.Form.quot_f_amount) {
          this.Form.quot_f_amount = this.selectedQuotation.resv_amount
        }

        console.log('form:', this.Form)
      })
        .catch(error => {
          if (error.response) {
            this.apierror.status = 'error'
            this.apierror.code = error.response.data.code
            this.apierror.messages = error.response.data.message
          }
        }).finally(() => {
          this.setOverlayStatus(false)
        })
    },

    submit(Form) {
      this.apierror.messages = []
      this.submitStatus = true

      const submitForm = {
        resv_id: Form.resv_id,
        quot_note: this.quot_note,
        quot_id: Form.quot_id,
        quot_d_amount: Form.quot_o_amount - Form.quot_f_amount,
        quot_f_amount: Form.quot_f_amount,

      }
      console.log('submitForm:', submitForm)
      this.editQuotation(submitForm)
        .then(() => {
          this.jumpToListPage()
        })
        .catch(error => {
          if (error.response) {
            this.apierror.status = 'error'
            this.apierror.code = error.response.data.code
            this.apierror.messages = error.response.data.message
          }
        })
        .finally(() => {
          this.submitStatus = false
        })
    },
  },
}
</script>
